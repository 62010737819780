import React from "react";

class YouTube extends React.Component {
    render() {
        return (
            <div
                className="video"
                style={{
                    position: "relative",
                    paddingBottom: "35%",
                    paddingTop: 45,
                    height: 'auto'
                }}
            >
                <iframe
                    title="videos"
                    name='youtube-video'
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                    }}
                    src={this.props.url}
                    frameBorder="0"
                    allowfullscreen="1"
                />
            </div>
        );
    }
}

export default YouTube;