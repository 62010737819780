import React, { useEffect } from "react";
import YouTube from "../common/YouTube";
import Slider from "react-slick";

function Portfolio() {
	useEffect(() => {
		document.title = "TilleyTech Media Ltd | Portfolio";
	});

	const examples = [
		{
			link: "https://tilleytempo.com/",
			image: require("../../assets/images/examples_tilleytempo.png"),
			alt:"TilleyTempo img"
		},
		{
			link: "https://tilleytees.com/",
			image: require("../../assets/images/examples_tilleytees.png"),
			alt:"TilleyTees img"
		},
		{
			link: "https://leightilleylifecoach.com/",
			image: require("../../assets/images/examples_ltlc.jpg"),
			alt:"LeighTilleyLifeCoach img"
		},
		{
			link: "https://wubl.world/",
			image: require("../../assets/images/examples_ww.jpg"),
			alt:"WUBLWorld img"
		},
	];

	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="row">
			<div className="col-12 my-4">
				<div className="home-content p-5 text-center">
					<Slider {...settings}>
						{examples.map((item) => (
							<div className="px-3">
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									<img style={{borderRadius: 14}} width="100%" src={item.image} alt={item.alt} />
								</a>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
}

export default Portfolio;
