import React from "react";

function LogoAnimation(props) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: props.logoColor,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<>
			<video
				width="320"
				height="140"
				autoPlay={true}
				controls={false}
				loop={true}
				muted={true}
			>
				<source
					src={require("../../../assets/images/header2_nobg.webm")}
					type="video/webm"
				/>
				Your browser does not support the video tag.
			</video>
		</>
	);
}

export default LogoAnimation;
