import React from 'react';
import { NavLink } from "react-router-dom";
import SiteLogo from "./logo/SiteLogo";

function NavBar() {

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <NavLink to="/" className="navbar-brand">
          <SiteLogo width={300} />
      </NavLink>
      <button className="navbar-toggler border-white" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 text-center">
          <li className="nav-item">
            <NavLink exact={true} to="/" className="nav-link" activeClassName="active">
              <img src={require('../../assets/images/home_icon.png')} alt="Home" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/about" className="nav-link" activeClassName="active">
              <img src={require('../../assets/images/about_icon.png')} alt="About" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/portfolio" className="nav-link" activeClassName="active">
              <img src={require('../../assets/images/portfolio_icon.png')} alt="portfolio" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/clients" className="nav-link" activeClassName="active">
              <img src={require('../../assets/images/clients_icon.png')} alt="clients" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contact" className="nav-link" activeClassName="active">
              <img src={require('../../assets/images/contact_icon.png')} alt="Contact" />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
