import React, { useEffect } from "react";
import YouTube from "../common/YouTube";

function Home() {
	useEffect(() => {
		document.title = "TilleyTech Media Ltd | Home";
	});

	return (
		<div className="row">
			<div className="col-12 my-4">
				<div className="home-content">
					<YouTube url="https://www.youtube.com/embed/g-wPZX_C77w?si=uj7P0iwuV8lWE4_v" />
				</div>
			</div>
		</div>
	);
}

export default Home;
