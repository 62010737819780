//import React, { useEffect } from "react";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//common components
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

//page components
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Portfolio from "./components/pages/Portfolio";
import Clients from "./components/pages/Clients";

//icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faTwitter,
	faInstagram,
	faFacebookF,
	faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
library.add(faTwitter, faInstagram, faFacebookF, faLinkedinIn);

function App() {
	return (
		<Router>
			<Header />
			<main role="main" className="container my-auto">
				<Switch>
					<Route exact path="/contact" component={Contact} />
					<Route exact path="/clients" component={Clients} />
					<Route exact path="/portfolio" component={Portfolio} />
					<Route exact path="/about" component={About} />
					<Route exact path="/" component={Home} />
				</Switch>
			</main>
			<Footer />
		</Router>
	);
}

export default App;
